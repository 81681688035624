{
  "single-wallet-testing": "单一钱包测试",
  "test-report": "测试报告",
  "test-result-initial": "请先填写左方的参数，再执行测试",
  "test-result-pass": "测试通过",
  "test-result-fail": "测试失败",
  "test-result-error": "发生异常错误",
  "result-subtitle-initial": "填写前请先详细阅读上方的指示说明",
  "result-subtitle-pass": "恭喜！您所介接的单一钱包 API 看起来没什么问题！",
  "result-subtitle-fail": "请检查及修正下方的测试结果，若有任何问题请汇出此份报告并传送给技术支援寻求协助",
  "result-subtitle-error": "请检查及修正下方的错误信息，若无法排除请汇出此份报告并传送给技术支援寻求协助",
  "passed-count": "测试通过用例数",
  "failed-count": "测试失败用例数",
  "test-result": "测试结果",
  "test-scenario": "测试情境",
  "pass": "通过",
  "fail": "失败",
  "warning": "警告",
  "resquest-name": "请求名称",
  "response-code": "响应状态码",
  "duration": "时间",
  "tests": "测试用例",
  "response-body": "Response Body",
  "request-body": "Request Body",
  "export-to-pdf": "汇出 PDF",
  "expand-all": "展开全部",
  "collapse-all": "收合全部",
  "required-parameters": "必填参数",
  "single-wallet-api": "单一钱包 API",
  "form-label-platform": "平台",
  "form-label-callbackUrl": "回调地址",
  "form-label-key": "客户端密钥",
  "form-label-userIdPrefix": "玩家 ID 前缀",
  "form-label-userId1": "玩家 ID 1",
  "form-label-userId2": "玩家 ID 2",
  "form-label-userId3": "玩家 ID 3",
  "form-label-currency": "币别",
  "form-label-betAmount": "下注金额",
  "form-label-balanceDecimalPlaces": "余额小数位数",
  "form-label-skippedTestScenarios": "指定略过测试情境",
  "skipped-test-scenarios": "略过指定测试情境",
  "rule-correct-url-format": "请输入正确的网址格式",
  "rule-enter-callback-url": "请输入回调地址",
  "rule-enter-key": "请输入客户端密钥",
  "rule-enter-user-id-prefix":"请输入玩家 ID 前缀",
  "rule-correct-user-id-prefix-format":"请输入正确的玩家 ID 前缀格式",
  "rule-enter-user-id-1": "请输入玩家 ID 1",
  "rule-enter-user-id-2": "请输入玩家 ID 2",
  "rule-enter-user-id-3": "请输入玩家 ID 3",
  "rule-correct-user-id-format": "请输入正确的玩家 ID 格式",
  "tip-user-id": "不需包括玩家 ID 前缀",
  "tip-user-id-same-currency": "不需包括玩家 ID 前缀，且所有玩家 ID 需使用相同币别",
  "rule-select-currency": "请选择币别",
  "tip-currency": "以上所有玩家 ID 需使用相同币别",
  "rule-enter-bet-amount": "请输入下注金额",
  "tip-bet-amount": "请考虑玩家下注限制",
  "tip-bet-amount-with-balance": "请考虑玩家下注限制，且玩家余额必须大于",
  "rule-select-balanceDecimalPlaces": "请选择余额小数位数",
  "tip-balanceDecimalPlaces": "贵司系统所支援玩家余额的小数位数",
  "rule-skippedTestScenarios": "请取消勾选至少一个指定略过测试情境",
  "check-all": "全选",
  "run-tests": "执行测试",
  "finished": "已完成",
  "test-scenario-unit": "个测试情境",
  "common-causes-of-error": "常见错误原因",
  "cause-callbackURL": "您的回调地址输入不正确",
  "cause-content-type": "您响应的 Content-type 类型不是",
  "cause-timeout": "请求超时 120000 毫秒",
  "test-purpose": "测试目的：",
  "test-operation": "操作：",
  "confirm": "确认",
  "negative-balance-bet-test": "负余额下注情境测试",
  "negative-balance-bet-test-purpose-msg":" 确保因为任意情形，当您的玩家余额为「负数」时，您不会接受任何该玩家的下注，以避免造成您的损失。",
  "negative-balance-bet-test-operation-msg1": "请您先将玩家 1",
  "negative-balance-bet-test-operation-msg2": "的余额调整为负数 (如：-10)，调整完成后点击「确认」继续执行负余额下注测试，此测试将于点击「确认」后 5 秒开始执行。",
  "zero-balance-bet-test": "零余额下注情境测试",
  "zero-balance-bet-test-purpose-msg":" 确保因为任意情形，当您的玩家余额为「零」时，您不会接受任何该玩家的下注，以避免造成您的损失。",
  "zero-balance-bet-test-operation-msg1": "请您先将玩家 1",
  "zero-balance-bet-test-operation-msg2": "的余额调整为 0，调整完成后点击「确认」继续执行零余额下注测试，此测试将于点击「确认」后 5 秒开始执行。",
  "finished-insufficient-balance-bet-test" :"测试已完成",
  "finished-insufficient-balance-bet-test-msg1": "请将玩家 1",
  "finished-insufficient-balance-bet-test-msg2": "的余额调回正数，谢谢。",
  "instruction": "指示说明",
  "supported-platforms-apis": "支援平台与 API",
  "platform": "游戏平台",
  "test-report-pdf-title": "测试报告 - AWC 单一钱包 API 测试",
  "parameter": "参数",
  "value": "值",
  "colon": "：",
  "confirm-settled-platformTxId-msg1": "请确认以下的 platformTxId：",
  "confirm-settled-platformTxId-msg2": "已在您的数据库中结算，且无任何未结单尚未结清",
  "": ""
}