<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<script>
import { provide } from 'vue'
import { useI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import cn from '@/locales/cn.json'

export default {
  name: 'App',
  setup() {
    const { t } = useI18n({
      messages: {
        en,
        cn,
      },
    })

    provide('t', t)

    return {
      t,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mukta');

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Mukta, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'!important;
  margin: 0;
}

code {
  font-size: 12px!important;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace!important;
  background: #eee;
  padding: 0 4px;
  border-radius: 2px;
  border: 1px solid rgba(38, 50, 56, 0.1);
}
</style>
