{
  "single-wallet-testing": "Single Wallet Testing",
  "test-report": "Test Report",
  "test-result-initial": "Please fill out the parameters on the left first, then run the tests!",
  "test-result-pass": "Test Passed",
  "test-result-fail": "Test Failed",
  "test-result-error": "An Error Occurred",
  "result-subtitle-initial": "Please refer to the instruction above before you fill in.",
  "result-subtitle-pass": "Congrats! Your API integration looks fine and great!",
  "result-subtitle-fail": "Please check and fix the following test result. Or export this report and send it to our technical support team for help.",
  "result-subtitle-error": "Please check and fix the following error message. Or export this report and send it to our technical support team for help.",
  "passed-count": "Passed Count",
  "failed-count": "Failed Count",
  "test-result": "Test Result",
  "test-scenario": "Test Scenario",
  "pass": "Pass",
  "fail": "Fail",
  "warning": "Warning",
  "resquest-name": "Resquest Name",
  "response-code": "Response Code",
  "duration": "Time",
  "tests": "Tests",
  "response-body": "Response Body",
  "request-body": "Request Body",
  "export-to-pdf": "Export to PDF",
  "expand-all": "Expand All",
  "collapse-all": "Collapse All",
  "required-parameters": "Required Parameters",
  "single-wallet-api": "Single-wallet APIs",
  "form-label-platform": "Platform",
  "form-label-callbackUrl": "Callback URL",
  "form-label-key": "Client Secret Key",
  "form-label-userIdPrefix": "User ID Prefix",
  "form-label-userId1": "User ID 1",
  "form-label-userId2": "User ID 2",
  "form-label-userId3": "User ID 3",
  "form-label-currency": "Currency",
  "form-label-betAmount": "Bet Amount",
  "form-label-balanceDecimalPlaces": "Decimal Places of Balance",
  "form-label-skippedTestScenarios": "Skipped Test Scenarios",
  "skipped-test-scenarios": "Skip the specified test scenarios",
  "rule-enter-callback-url": "Please enter the callback URL",
  "rule-correct-url-format": "Please enter the correct URL format",
  "rule-enter-key": "Please enter the client secret key",
  "rule-enter-user-id-prefix":"Please enter user ID prefix",
  "rule-correct-user-id-prefix-format":"Please enter the correct format of user ID prefix",
  "rule-enter-user-id-1": "Please enter the user ID 1",
  "rule-enter-user-id-2": "Please enter the user ID 2",
  "rule-enter-user-id-3": "Please enter the user ID 3",
  "rule-correct-user-id-format": "Please enter the correct format of user ID",
  "tip-user-id": "Do not include the user ID prefix",
  "tip-user-id-same-currency": "Do not include the user ID prefix, and all user IDs must use the same currency",
  "rule-select-currency": "Please select the currency",
  "tip-currency": "Please use the same currency for all above user IDs",
  "rule-enter-bet-amount": "Please enter the bet amount",
  "tip-bet-amount": "Please consider the user bet limit",
  "tip-bet-amount-with-balance": "Please consider the user bet limit, and the user balance must be greater than",
  "rule-select-balanceDecimalPlaces": "Please select the decimal places of balance",
  "tip-balanceDecimalPlaces": "Number of decimal places for player balances supported by your system",
  "rule-skippedTestScenarios": "Please uncheck at least 1 skipped test scenario",
  "finished": "Finished",
  "test-scenario-unit": "test scenarios",
  "common-causes-of-error": "Common causes of error",
  "cause-callbackURL": "Your callback URL was not entered correctly",
  "cause-content-type": "The content-type of your response is not",
  "cause-timeout": "The request timed out after 120000 ms",
  "test-purpose": "Test purpose:",
  "test-operation": "Operation:",
  "confirm": "Confirm",
  "negative-balance-bet-test": "Negative Balance Bet Scenario Test",
  "negative-balance-bet-test-purpose-msg": "We want to help you to make sure that due to any incidents, when your player balance is \"negative\", any bets from that player should fail and be rejected to avoid your losses.",
  "negative-balance-bet-test-operation-msg1": "Please help us to adjust the balance of user 1",
  "negative-balance-bet-test-operation-msg2": "to a negative number (ex: -10) then click \"Confirm\". This test will start to execute 5 seconds after you click \"Confirm\".",
  "finished-negative-balance-bet-test" :"Negative Balance Bet Scenario Test Finished",
  "finished-negative-balance-bet-test-msg1": "If the test result of this negative balance bet scenario is \"Pass\", please adjust the balance of user 1",
  "finished-negative-balance-bet-test-msg2": "back to a positive number, thank you.",
  "zero-balance-bet-test": "Zero Balance Bet Scenario Test",
  "zero-balance-bet-test-purpose-msg": "We want to help you to make sure that due to any incidents, when your player balance is \"zero\", any bets from that player should fail and be rejected to avoid your losses.",
  "zero-balance-bet-test-operation-msg1": "Please help us to adjust the balance of user 1",
  "zero-balance-bet-test-operation-msg2": "to 0 then click \"Confirm\". This test will start to execute 5 seconds after you click \"Confirm\".",
  "finished-insufficient-balance-bet-test" :"Test Finished",
  "finished-insufficient-balance-bet-test-msg1": "Please adjust the balance of user 1",
  "finished-insufficient-balance-bet-test-msg2": "back to a positive number, thank you.",
  "check-all": "Check All",
  "run-tests": "Run Tests",
  "instruction": "Instruction",
  "supported-platforms-apis": "Supported Platforms & APIs",
  "platform": "Platform",
  "test-report-pdf-title": "Test Report - AWC Single Wallet API Testing",
  "parameter": "Parameter",
  "value": "Value",
  "colon": ": ",
  "confirm-settled-platformTxId-msg1": "Please confirm that the below platformTxId:",
  "confirm-settled-platformTxId-msg2": "have been settled in your database, and no any outstanding ticket is yet to be settled.",
  "": ""
}
